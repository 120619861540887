import React, { ReactNode } from "react";
import MuiDialog from '@mui/material/Dialog'
import { DialogTitle, DialogActions, DialogContent, Box, DialogProps as MUIDialogProps, SxProps } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, LoadingButton, SaveButton } from '../Button'
import { Typography } from "../Typography";
import { Theme } from "@mui/system";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

interface DialogV2Props extends Omit<MUIDialogProps, "title"> {
  title: string | ReactNode
  subtitle?: string | ReactNode
  subtitleVariant?: 'body1' | 'p2'
  onClose: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg'
  headerActions?: ReactNode[]
  primaryLabel?: string
  primaryAction?: () => void
  formName?: string
  submitting?: boolean
  overridePrimaryComponent?: ReactNode
  cancelLabel?: string
  cancelAction?: () => void
  secondaryLabel?: string
  showCancelButton?: boolean
  secondaryAction?: () => void
  secondaryVariant?: 'outlined' | 'text' | 'contained'
  overrideSecondaryComponent?: ReactNode
  chips?: ReactNode[]
  dataTestId?: string
  preventScroll?: boolean
  contentSx?: SxProps<Theme>
  showActions?: boolean
  printComponentRef?: React.RefObject<any>,
  className?: string
}

export const DialogV2 = ({
  open,
  title,
  subtitle,
  subtitleVariant = 'p2',
  children,
  size='md',
  onClose,
  headerActions,
  primaryLabel = 'save',
  primaryAction,
  formName,
  submitting,
  cancelLabel = 'cancel',
  cancelAction,
  secondaryAction,
  secondaryLabel,
  showCancelButton = true,
  secondaryVariant = 'outlined',
  chips,
  dataTestId,
  overridePrimaryComponent,
  overrideSecondaryComponent,
  preventScroll = false,
  contentSx,
  showActions = true,
  printComponentRef,
  className,
  ...remainingProps
}: DialogV2Props) => {

  let primaryButton = <Button
      dataTestId={`${dataTestId}-primary-button`}
      name={'dialog_primary'}
      onClick={primaryAction}
    >
      { primaryLabel }
    </Button>

  let secondaryButton = secondaryAction && secondaryLabel &&
    <Button
      dataTestId={`${dataTestId}-secondary-button`}
      name={'dialog_secondary_button'}
      onClick={secondaryAction}
      variant={secondaryVariant}
    >
      { secondaryLabel }
    </Button>

  if (formName) {
    if(submitting === null){
      console.warn("Form name is supplied, but submitting prop is null")
    }
    primaryButton = <SaveButton
      form={formName}
      submitting={!!submitting}
      dataTestId={`${dataTestId}-primary`}
    />
  }

  return <MuiDialog
    data-testid={dataTestId}
    open={open}
    TransitionComponent={Transition}
    fullWidth={true}
    maxWidth={size}
    onClose={onClose}
    ref={printComponentRef}
    className={`${className || ''} modal`.trim()}
    PaperProps={{
      className: className ? `${className}-paper` : undefined,
    }}
    {...remainingProps}
  >
    <DialogTitle sx={{p: 1}}>
      <Box display={'flex'} alignItems={'start'}>
        <Box display={'inline-flex'} flexDirection={'column'} sx={{flex:'1 1 auto'}}>
          <Typography data-testid={`${dataTestId}-title`} variant={'h2'} lineclamp={1} > { title } </Typography>
          {subtitle && <Typography data-testid={`${dataTestId}-subtitle`} variant={subtitleVariant}>{subtitle}</Typography>}
          {chips}
        </Box>
        {headerActions && <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
          { headerActions }
        </Box>}
        <IconButton
          name={'dialog-header-close-button'}
          dataTestId={`${dataTestId}-close-button`}
          onClick={onClose}
          icon={<CloseIcon/>}
        />
      </Box>
    </DialogTitle>

    <DialogContent
      className={`${className ? className+"-content" : ''} modal-content`.trim()}
      sx={{
        px: 1,
        py: 0,
        overflowY: preventScroll ? 'hidden' : 'auto',
        ...contentSx
      }}
    >
      {children}
    </DialogContent>

    {showActions && <DialogActions disableSpacing={true} sx={{p: 1, gap: 2}}>
      {showCancelButton && <Button dataTestId={`${dataTestId}-cancel-button`} name={'dialog_secondary'} onClick={cancelAction || onClose} variant={'text'}>{ cancelLabel }</Button>}

      { overrideSecondaryComponent || secondaryButton }

      { overridePrimaryComponent || primaryButton }

    </DialogActions>}
  </MuiDialog>
}

interface ConfirmationDialogProps{
  title: string
  message: string | React.ReactNode
  open: boolean
  primaryAction: ()=>void
  primaryLabel?: string
  primaryVariant?: 'outlined' | 'text' | 'contained'
  secondaryAction?: ()=>void
  secondaryLabel?: string
  secondaryVariant?: 'outlined' | 'text' | 'contained'
  onClose?: ()=>void
  closeLabel?: string
  dataTestId?: string
  isSubmitting?: boolean
}
export const ConfirmationDialog = ({
  title,
  message,
  open,
  onClose,
  closeLabel,
  primaryAction,
  primaryLabel,
  primaryVariant = "text",
  secondaryAction,
  secondaryLabel,
  secondaryVariant = "outlined",
  dataTestId = "confirmationDialog",
  isSubmitting = false,
}: ConfirmationDialogProps) => {
  return <MuiDialog
    data-testid={dataTestId}
    open={open}
    TransitionComponent={Transition}
    maxWidth={'sm'}
  >
    <Box sx={{px: 3, py:2}}>
      <Typography variant={'h2'} dataTestId={`${dataTestId}-title`}> { title } </Typography>
    </Box>

    <DialogContent sx={{pt: "0px !important", px:3, pb: 2}}>
      {typeof message === 'string'
        ? <Typography variant={'body1'} dataTestId={`${dataTestId}-message`}> {message} </Typography>
        : message
      }
    </DialogContent>

    <DialogActions disableSpacing={true} sx={{p: 1, gap: 2}}>
      {onClose &&
        <Button
          dataTestId={`${dataTestId}-cancel`}
          name={'confirmation_cancel_button'}
          onClick={onClose}
          variant={'text'}
        >
          { closeLabel || 'cancel' }
        </Button>
      }
      {secondaryAction && secondaryLabel &&
        <Button
          dataTestId={`${dataTestId}-secondary-button`}
          name={'dialog_secondary_button'}
          onClick={secondaryAction}
          variant={secondaryVariant}
        >
          { secondaryLabel }
        </Button>
      }
      <LoadingButton
        dataTestId={`${dataTestId}-primaryAction`}
        name={'dialog_primary'}
        onClick={primaryAction}
        variant={primaryVariant}
        loading={isSubmitting}
      >
        { primaryLabel || 'ok' }
      </LoadingButton>
    </DialogActions>
  </MuiDialog>;
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default DialogV2