import React from 'react'
import { Box, Paper, Theme } from '@mui/material'
import { Typography } from '../Typography'
import { SxProps } from "@mui/system";
import { NavigationHeader_V2, NavigationHeaderProps_V2 } from "../NavigationHeader/NavigationHeader";

type SectionContainerProps ={
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  subform?: boolean,
  dataTestId?: string
}
export const SectionContainer = ({children, sx = {}, dataTestId}:SectionContainerProps) => {
  return (
    <Box
      data-testid={dataTestId}
      className={"section_container"}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        flex: '1 1 auto',
        pb: 0,
        height: "100%",
        boxSizing: "border-box",
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

type FormSectionProps ={
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  label?: string,
  refElement?: React.Ref<HTMLDivElement>
  dataTestId?: string
}
export const FormSection = ({children, sx, label, refElement, dataTestId}: FormSectionProps) => {
  return (
    <Box
      data-testid={dataTestId}
      className={"section_formsection"}
      sx={{
        display: 'flex',
        flexDirection: "column",
        flex: '1 1 auto',
        mb: 2,
        ...sx
      }}
      ref={refElement}
    >
      {label && <SectionSubHeader>{label}</SectionSubHeader>}
      {children}
    </Box>
  )
}

type SectionHeaderProps = {
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  icon?: React.ReactNode;
  lineclamp?: number,
  dataTestId?: string
}
export const SectionHeader = ({ children,
  sx = {},
  icon,
  lineclamp,
  dataTestId,
}: SectionHeaderProps) => {
  return (
    <Box
      data-testid={dataTestId}
      className={'sectionHeader'}
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 1,
        mb: 2,
        ...sx,
      }}
    >
      {icon}
      <Typography
        variant={'h2'}
        lineclamp={lineclamp}
      >
        {children}
      </Typography>
    </Box>
  )
}


type SectionSubHeaderProps = {
  children: React.ReactNode,
  variant?: 'h3' | 'h5',
  sx?: SxProps<Theme>,
  dataTestId?: string
}

export const SectionSubHeader = ({ children, variant = 'h5',  sx = {}, dataTestId = ''}: SectionSubHeaderProps) => {
  return (
    <Typography
      data-testid={dataTestId}
      variant={variant}
      className={'sectionHeader'}
      sx={{
        display: "flex",
        alignItems: 'center',
        m: (theme) => theme.spacing(0, 0, variant === 'h3' ? 2 : 1, 0),
        ...sx
      }}
    >
      {children}
    </Typography>
  )
}

export interface SectionColumnProps {
  id?: string,
  width?: string,
  height?: string,
  rightPadding?: number,
  gap?: number,
  elevation?: number,
  children: React.ReactNode,
  sx?: SxProps<Theme>,
  onMouseUp?: () => void,
  header?: React.ReactNode,
  preventScroll?: boolean,
  refElement?: React.Ref<HTMLDivElement>
  innerRefElement?: React.Ref<HTMLDivElement>
  headerLabel?: string
  onBack?: ()=>void
  headerProps?:NavigationHeaderProps_V2
  dataTestId?: string
}

export const SectionColumn = ({
  width ='auto',
  height='calc(100% - 3px)',
  elevation = 1,
  rightPadding = 0,
  gap,
  children,
  sx,
  onMouseUp,
  header,
  headerProps,
  preventScroll = false,
  refElement,
  innerRefElement,
  headerLabel,
  onBack,
  id,
  dataTestId
}: SectionColumnProps) => {
  if(!!header && !!headerLabel) {
    console.log("SectionColumn: header and headerProps are both defined; headerLabel will be ignored.")
  }

  return (
    <Paper
      data-testid={dataTestId}
      id={id}
      className={'section-column'}
      elevation={elevation}
      sx={{
        ml: 0,
        mr: 1,
        mt: 0,
        mb: 0,
        width: width,
        minWidth: '250px',
        p: 1,
        pr: 0,
        height: height,
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'column',
        ...sx
      }}
      onMouseUp={onMouseUp}
      ref={refElement}
    >
      {header && <Box sx={{mr:1}}> { header } </Box>}
      {headerLabel && <NavigationHeader_V2 title={headerLabel} onBack={onBack} {...headerProps} sx={{mr:1}}/>}
      <Box
        ref={innerRefElement}
        className={"section-column-content"}
        display={'flex'}
        flexDirection={'column'}
        sx={{
          overflow: preventScroll ? 'hidden' : 'auto',
          flex: '1 1 auto',
          pr: rightPadding,
          gap: gap,
          height: '100%',
      }}>
        {children}
      </Box>
    </Paper>
  )
}

export const SectionColumnNoElevation = (props: SectionColumnProps) => {
  const {sx, children, ...rest} = props
  return (
    <SectionColumn elevation={0} children={children} sx={{ height: '100%', flex: 1, ...sx}} {...rest} />
  )
}

export const Section = {
  Header: SectionHeader,
  SubHeader: SectionSubHeader,
  Column: SectionColumn,
  ColumnNoElevation : SectionColumnNoElevation,
  Container: SectionContainer,
  FormSection: FormSection,
}

export default Section
