import { Theme, Box, useTheme } from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import React, { useCallback, useMemo } from "react";
import { SxProps } from "@mui/system";
import { Typography } from "../Typography";
import { useDropzone, DropEvent, FileRejection, ErrorCode, Accept } from 'react-dropzone'
import { LinkButtonHref } from "../LinkButtonHref";
import _uniqWith from 'lodash/uniqWith'
import { IconButton } from "../Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { LinearProgressWithLabel } from "../LinearProgressWithLabel";
import { formatFileSizeInMB, BaseFileWithProgress } from "../../util/azureStorage";
import { Controller, useFormContext } from "../Form";
import { useSchemaRequired } from "../../hooks/useSchemaRequired";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
const maxFileSize : number = 52428800 // 50MB

export interface FileUploadConfiguration {
  sx?: SxProps<Theme>
  files: BaseFileWithProgress[]
  setFiles: (files: BaseFileWithProgress[]) => void
  uploading: boolean
  dataTestId?: string
  multiple?: boolean
  dragAndDropEndText?: string
  fileText?: string
  acceptedFileTypes?: Accept
  supportedFormats?: string
  borderColor?: string
  disabled?: boolean
}

export interface ControlledFileUploadConfiguration extends FileUploadConfiguration {
  name: string
  label?: string
  required?: boolean
}

const areFilesEqual = (file1: File, file2: File) => {
  return file1.name === file2.name && file1.size === file2.size && file1.lastModified === file2.lastModified
}

function fileSizeValidator(file) {
  if (file.size > maxFileSize) {
    return {
      code: ErrorCode.FileTooLarge,
      message: `File is larger than 50MB`
    };
  }
  return null
}

export const FileUpload = ({
  sx={},
  files,
  setFiles,
  uploading=false,
  dataTestId,
  multiple=true,
  dragAndDropEndText,
  fileText="file",
  acceptedFileTypes={'application/pdf': []},
  supportedFormats="PDF",
  borderColor,
  disabled=false
}: FileUploadConfiguration) => {
  const { showErrorAlert } = useAlerts()
  const theme = useTheme()

  const fileCountValidator = useCallback((file) => {
    if (files.length > 0 && !multiple) {
      return {
        code: ErrorCode.TooManyFiles,
        message: 'Too many files'
      }
    }
    return null
  }, [files, multiple])

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => {
    if (uploading) return

    rejectedFiles.forEach((rejectedFile) => {
      //TODO: the convention for showing multiple error messages is in claim entry
      showErrorAlert(`File ${rejectedFile.file.name} was rejected. ${rejectedFile.errors[0].message}`)
    })

    let uniqueFiles : File[] = _uniqWith([...files, ...acceptedFiles], areFilesEqual)
    setFiles(uniqueFiles)
  }, [files, setFiles, showErrorAlert, uploading])

  const {getRootProps, getInputProps, isDragActive} = useDropzone(
    {
      onDrop,
      accept: {...acceptedFileTypes},
      validator: (file) => fileCountValidator(file) ?? fileSizeValidator(file),
      multiple: multiple,
      maxFiles: multiple ? 0 : 1,
      disabled: (!multiple && files.length > 0) || disabled
    }
  )

  const baseStyle: React.CSSProperties = useMemo(() => {
    return {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '8px',
      border: '3px dashed',
      borderColor: borderColor ?? theme.palette.greys.light,
      borderRadius: '8px',
      borderStyle: 'dashed',
      outline: 'none',
      transition: 'border .24s ease-in-out',
      justifyContent: 'center',
      cursor: 'pointer',
      height: '220px',
    }
  }, [borderColor, theme.palette.greys.light]);

  const activeDragStyle = useMemo(() => {
    return {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.greys.extraLight,
    }
  }, [theme.palette.greys.extraLight, theme.palette.primary.main]);

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeDragStyle : {}),
  }), [activeDragStyle, baseStyle, isDragActive]);

  fileText += multiple ? '(s)' : ''
  const selectFileText = `file${multiple ? '(s)' : ''}`

  return (
    <>
      <Box data-testid={`${dataTestId}-drop-area`} sx={{...sx}} {...getRootProps({style})}>
        <><input {...getInputProps()} /></>
        <RowBox>
          <FileUploadOutlinedIcon sx={{color: 'greys.medium', fontSize: '4rem'}} />
        </RowBox>
        <RowBox>
        {
          isDragActive ?
            <Typography variant={'h6'} sx={{color: 'greys.dark'}}>{`Drop ${fileText} here...`}</Typography> :
            <Typography align={'center'} variant={'h6'} sx={{color: 'greys.dark'}}>
              {`Drag and drop ${fileText} here or` }
              <LinkButtonHref text={`Select ${selectFileText}`} onClick={() => {}}/>
              {dragAndDropEndText ? ` ${dragAndDropEndText}.` : '.'}
            </Typography>
        }
        </RowBox>
      </Box>
      <RowBox
        sx={{
          justifyContent: 'space-between',
          py: 1,
          ...sx
        }}
      >
        <RowBox>
          <Typography variant={'body1'} sx={{color: 'greys.dark'}}>Supported formats: </Typography>
          <Typography pl={0.5} variant={'h5'}>{supportedFormats}</Typography>
        </RowBox>
        <RowBox>
          <Typography variant={'body1'} sx={{color: 'greys.dark'}}>Maximum size: </Typography>
          <Typography pl={0.5} variant={'h5'}>50MB</Typography>
        </RowBox>
      </RowBox>
      <FileUploadList dataTestId={`${dataTestId}-file-list`} files={files} setFiles={setFiles} uploading={uploading}/>
    </>
  )
}

const RowBox = ({sx={}, children}) => {
  return <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx
    }}
  >
    {children}
  </Box>
}

export default FileUpload

interface FileUploadListProps {
  files: BaseFileWithProgress[]
  setFiles: (files: BaseFileWithProgress[]) => void
  uploading: boolean
  dataTestId?: string
}
const FileUploadList = ({files, setFiles, uploading=false, dataTestId}: FileUploadListProps) => {

  const removeFile = (file: File) => {
    const newFiles = files.filter((f) => {
      return !areFilesEqual(f, file)
    })
    setFiles(newFiles)
  }

  const listItems = files.map((file, index) => {
      return <FileUploadListItem dataTestId={`${dataTestId}-item-${index}`} key={index} itemIndex={index} file={file} removeFile={removeFile} uploading={uploading}/>
    })

  if (listItems.length === 0) return null

  return (
    <Box
      data-testid={dataTestId}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        overflowY: 'auto',
        maxHeight: '220px',
        width: '100%',
      }}
    >
      {listItems}
    </Box>
  )
}

interface FileUploadListItemProps {
  file: BaseFileWithProgress
  removeFile: (file: BaseFileWithProgress) => void
  itemIndex: number
  uploading: boolean
  dataTestId?: string
}

const FileUploadListItem = ({file, removeFile, itemIndex, uploading=false, dataTestId}: FileUploadListItemProps) => {
  return (
    <Box
      key={itemIndex}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        border: '3px solid',
        borderColor: 'primary.main',
        borderRadius: '8px',
        p: 2,
        mb: 1,
        mr: 1,
        width: '44%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography data-testid={`${dataTestId}-file-name`} variant={'body1'} sx={{color: 'greys.dark'}} lineclamp={1}>{file.name}</Typography>
          <Typography data-testid={`${dataTestId}-file-size`} variant={'body2'} sx={{color: 'greys.light'}}>{formatFileSizeInMB(file.size)}</Typography>
        </Box>
        <IconButton dataTestId={`${dataTestId}-remove-file-button`} icon={<DeleteOutlineIcon/>} disabled={uploading} onClick={() => {removeFile(file)}} />
      </Box>
      <LinearProgressWithLabel dataTestId={`${dataTestId}-progress`} sx={{ width: '100%', mt: 1 }} value={(file.progress ?? 0)} displayProgress={uploading} />
    </Box>
  )
}

export const ControlledFileUpload = ({
  name,
  label,
  required,
  sx={},
  files,
  setFiles,
  uploading=false,
  dataTestId,
  multiple=true,
  dragAndDropEndText,
  fileText,
  acceptedFileTypes,
  supportedFormats
}: ControlledFileUploadConfiguration) => {
  const { control } = useFormContext()
  const schemaRequired = useSchemaRequired(name)
  const theme = useTheme()

  return <Controller
    name={name}
    control={control}
    render={({ field: { value }, fieldState: {error} }) => {
      return <>
        {label && <FormLabel error={!!error} required={(required ?? schemaRequired) && !value}>{label}</FormLabel>}
        <FileUpload
          sx={sx}
          files={files}
          setFiles={setFiles}
          uploading={uploading}
          dataTestId={dataTestId}
          multiple={multiple}
          dragAndDropEndText={dragAndDropEndText}
          fileText={fileText}
          acceptedFileTypes={acceptedFileTypes}
          supportedFormats={supportedFormats}
          borderColor={!!error ? theme.palette.error.main : undefined}
        />
        {error?.message && <FormHelperText error={!!error}>{error?.message?.toString()}</FormHelperText>}
      </>}
    }
  />
}