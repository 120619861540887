import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Box, SxProps, Theme } from '@mui/system'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import _get from 'lodash/get'

// Importing all plugins
import 'froala-editor/js/froala_editor.pkgd.min.js' // Froala Editor with the plugins included
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/js/third_party/embedly.min.js'
import 'froala-editor/js/third_party/font_awesome.min.js'
import 'froala-editor/js/third_party/image_tui.min.js'
import 'froala-editor/js/third_party/spell_checker.min.js'
// Importing all related css
import 'froala-editor/css/froala_style.css'
import 'froala-editor/css/froala_editor.pkgd.css' // Froala Editor styles with the plugins included
import 'froala-editor/css/third_party/embedly.min.css'
import 'froala-editor/css/third_party/font_awesome.min.css'
import 'froala-editor/css/third_party/image_tui.min.css'
import 'froala-editor/css/third_party/spell_checker.min.css'
import FroalaEditor from 'react-froala-wysiwyg'
import Froala from 'froala-editor'
// Importing custom css
import './RichTextEditor.css'
import { useFormContext } from '../Form'
import { Controller } from 'react-hook-form'
import { renderToStaticMarkup } from 'react-dom/server'
import moment from 'moment-timezone'
import {
  dateFormats,
  APPOINTMENT_MENU_PLUGIN,
  AUTOFILL_MENU_PLUGIN,
  PRACTITIONER_MENU_PLUGIN,
  LETTER_PRACTITIONER_MENU_PLUGIN,
  REFERRING_PRACTITIONER_MENU_PLUGIN,
  FAMILY_PRACTITIONER_MENU_PLUGIN,
  PATIENT_HEALTH_DATA_MENU_PLUGIN,
  LOCATION_MENU_PLUGIN,
  APPOINTMENT_LOCATION_MENU_PLUGIN,
  APPOINTMENT_PRACTITIONER_MENU_PLUGIN,
  GENERAL_MENU_PLUGIN,
  DATE_MENU_PLUGIN,
  PRONOUN_MENU_PLUGIN,
  PATIENT_PROFILE_MENU_PLUGIN,
  DATE_STRING,
  TODAYS_DATE_STRING,
  DATE_OF_BIRTH_STRING,
  autofillButtons,
  patientButtons,
  patientHealthDataButtons,
  appointmentButtons,
  pronounButtons,
  secondLayerMenus,
  generalButtons,
  locationButtons,
  practitionerButtons,
  practitionerMenuButtons,
  thirdLayerMenus,
  PRACTITIONER_TITLE_LAST_NAME,
  PRACTITIONER_TITLE_FULL_NAME, SIGNATURE_STRING
} from './LetterTemplateChipConstants'
import { DocumentNode, useLazyQuery } from '@apollo/client'

const dateFormatButtons = dateFormats.map((format) => ({
  label: format,
  subLabel: moment().format(format)
}))

const BUTTON_OFFSET = 210

Froala.POPUP_TEMPLATES[AUTOFILL_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[PATIENT_PROFILE_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[APPOINTMENT_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[PRACTITIONER_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[LETTER_PRACTITIONER_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[REFERRING_PRACTITIONER_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[FAMILY_PRACTITIONER_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[PATIENT_HEALTH_DATA_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[LOCATION_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[APPOINTMENT_LOCATION_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[APPOINTMENT_PRACTITIONER_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[GENERAL_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[DATE_MENU_PLUGIN] = '[_BUTTONS_]'
Froala.POPUP_TEMPLATES[PRONOUN_MENU_PLUGIN] = '[_BUTTONS_]'

const showMenu = (editor, initPopup, buttonLabel, menuName, parentMenuName, useButtonOffset = false) => {
  var $popup = editor.popups.get(menuName)
  if (!$popup) initPopup()
  editor.popups.setContainer(menuName, editor.$tb)
  var buttonCommand = !useButtonOffset ? buttonLabel : `${buttonLabel}-${menuName}-${parentMenuName}`
  var $btn = editor.$tb.find(`.fr-command[data-cmd="${buttonCommand}"]`)
  var left = useButtonOffset ? $btn.offset().left + BUTTON_OFFSET : $btn.offset().left
  var top = editor.opts.toolbarBottom
  editor.popups.show(menuName, left, top, $btn.outerHeight())

  var autofillPopup = editor.popups.get(AUTOFILL_MENU_PLUGIN)
  if (autofillPopup) autofillPopup.addClass('fr-active')

  if (parentMenuName === PATIENT_PROFILE_MENU_PLUGIN) {
    var patientProfilePopup = editor.popups.get(PATIENT_PROFILE_MENU_PLUGIN)
    if (patientProfilePopup) patientProfilePopup.addClass('fr-active')
  }

  if (parentMenuName === PRACTITIONER_MENU_PLUGIN) {
    var practitionerPopup = editor.popups.get(PRACTITIONER_MENU_PLUGIN)
    if (practitionerPopup) practitionerPopup.addClass('fr-active')
  }

  if (parentMenuName === APPOINTMENT_MENU_PLUGIN || parentMenuName === APPOINTMENT_PRACTITIONER_MENU_PLUGIN) {
    var appointmentPopup = editor.popups.get(APPOINTMENT_MENU_PLUGIN)
    if (appointmentPopup) appointmentPopup.addClass('fr-active')
  }
}

const hideMenu = (editor, menuName) => {
  editor.popups.hide(menuName)
}

Froala.PLUGINS.autofillMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    for (let i = 0; i < autofillButtons.length; i++) {
      let label = autofillButtons[i].label
      let pluginName = autofillButtons[i].pluginName
      popup_buttons += RegisterSubMenuButton(label, pluginName, AUTOFILL_MENU_PLUGIN)
    }

    var $popup = editor.popups.create(AUTOFILL_MENU_PLUGIN, {
      buttons: popup_buttons
    })

    $popup.addClass('placeholder-container')

    return $popup
  }


  return {
    showPopup: () => showMenu(editor, initPopup, 'autofillButton', AUTOFILL_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN),
    hidePopup: () => hideMenu(editor, AUTOFILL_MENU_PLUGIN)
  }
}

Froala.PLUGINS.patientProfileMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    patientButtons.map((label) => {
      if (label !== DATE_OF_BIRTH_STRING && label !== 'Pronouns') {
        popup_buttons += RegisterMenuButton(label, 'Patient profile', PATIENT_PROFILE_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN)
      } else {
        if (label === DATE_OF_BIRTH_STRING) {
          popup_buttons += RegisterSubMenuButton(label, DATE_MENU_PLUGIN, PATIENT_PROFILE_MENU_PLUGIN)
        } else if (label === 'Pronouns') {
          popup_buttons += RegisterSubMenuButton(label, PRONOUN_MENU_PLUGIN, PATIENT_PROFILE_MENU_PLUGIN)
        }
      }
    })

    return editor.popups.create(PATIENT_PROFILE_MENU_PLUGIN,
      {
        buttons: popup_buttons
      }
    )
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Patient profile', PATIENT_PROFILE_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, PATIENT_PROFILE_MENU_PLUGIN)
  }
}

Froala.PLUGINS.patientHealthDataMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    patientHealthDataButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, 'Patient health data', PATIENT_HEALTH_DATA_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN)
    })

    return editor.popups.create(PATIENT_HEALTH_DATA_MENU_PLUGIN,
      {
        buttons: popup_buttons
      }
    )
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Patient health data', PATIENT_HEALTH_DATA_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, PATIENT_HEALTH_DATA_MENU_PLUGIN)
  }
}

Froala.PLUGINS.appointmentMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    appointmentButtons.map((label) => {
      if (label !== DATE_STRING && label !== 'Practitioner' && label !== 'Location') {
        popup_buttons += RegisterMenuButton(label, 'Appointment', APPOINTMENT_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN)
      } else {
        if (label === DATE_STRING) {
          popup_buttons += RegisterSubMenuButton(label, DATE_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN)
        } else if (label === 'Practitioner') {
          popup_buttons += RegisterSubMenuButton(label, APPOINTMENT_PRACTITIONER_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN)
        } else if (label === 'Location') {
          popup_buttons += RegisterSubMenuButton(label, APPOINTMENT_LOCATION_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN)
        }
      }
    })

    return editor.popups.create(APPOINTMENT_MENU_PLUGIN,
      {
        buttons: popup_buttons
      }
    )
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Appointment', APPOINTMENT_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, APPOINTMENT_MENU_PLUGIN)
  }
}

Froala.PLUGINS.practitionerMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    for (let i = 0; i < practitionerMenuButtons.length; i++) {
      let label = practitionerMenuButtons[i].label
      popup_buttons += RegisterSubMenuButton(label, practitionerMenuButtons[i].pluginName, PRACTITIONER_MENU_PLUGIN)
    }

    return editor.popups.create(PRACTITIONER_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Practitioner', PRACTITIONER_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, PRACTITIONER_MENU_PLUGIN)
  }
}

Froala.PLUGINS.pronounMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    pronounButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, 'Pronouns', PRONOUN_MENU_PLUGIN, PATIENT_PROFILE_MENU_PLUGIN)
    })

    return editor.popups.create(PRONOUN_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Pronouns', PRONOUN_MENU_PLUGIN, PATIENT_PROFILE_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, PRONOUN_MENU_PLUGIN)
  }
}

Froala.PLUGINS.letterPractitionerMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    practitionerButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, practitionerMenuButtons[0].label, LETTER_PRACTITIONER_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN)
    })
    popup_buttons += RegisterMenuButton(SIGNATURE_STRING, practitionerMenuButtons[0].label, LETTER_PRACTITIONER_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN)

    return editor.popups.create(LETTER_PRACTITIONER_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, practitionerMenuButtons[0].label, LETTER_PRACTITIONER_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, LETTER_PRACTITIONER_MENU_PLUGIN)
  }
}

Froala.PLUGINS.referringPractitionerMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    practitionerButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, practitionerMenuButtons[1].label, REFERRING_PRACTITIONER_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN)
    })

    return editor.popups.create(REFERRING_PRACTITIONER_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, practitionerMenuButtons[1].label, REFERRING_PRACTITIONER_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, REFERRING_PRACTITIONER_MENU_PLUGIN)
  }
}

Froala.PLUGINS.familyPractitionerMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    practitionerButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, practitionerMenuButtons[2].label, FAMILY_PRACTITIONER_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN)
    })

    return editor.popups.create(FAMILY_PRACTITIONER_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, practitionerMenuButtons[2].label, FAMILY_PRACTITIONER_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, FAMILY_PRACTITIONER_MENU_PLUGIN)
  }
}

Froala.PLUGINS.appointmentPractitionerMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    practitionerButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, 'Practitioner', APPOINTMENT_PRACTITIONER_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN)
    })

    return editor.popups.create(APPOINTMENT_PRACTITIONER_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Practitioner', APPOINTMENT_PRACTITIONER_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, APPOINTMENT_PRACTITIONER_MENU_PLUGIN)
  }
}

Froala.PLUGINS.appointmentLocationMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    locationButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, 'Location', APPOINTMENT_LOCATION_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN)
    })

    return editor.popups.create(APPOINTMENT_LOCATION_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Location', APPOINTMENT_LOCATION_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, APPOINTMENT_LOCATION_MENU_PLUGIN)
  }
}

Froala.PLUGINS.locationMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    locationButtons.map((label) => {
      popup_buttons += RegisterMenuButton(label, 'Location', LOCATION_MENU_PLUGIN, LOCATION_MENU_PLUGIN)
    })

    return editor.popups.create(LOCATION_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'Location', LOCATION_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, LOCATION_MENU_PLUGIN)
  }
}

Froala.PLUGINS.generalMenuPlugin = function(editor) {
  function initPopup() {
    var popup_buttons = ''

    generalButtons.map((label) => {
      if (label === TODAYS_DATE_STRING) {
        popup_buttons += RegisterSubMenuButton(label, DATE_MENU_PLUGIN, GENERAL_MENU_PLUGIN)
      } else {
        popup_buttons += RegisterMenuButton(label, 'General', GENERAL_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN)
      }
    })

    return editor.popups.create(GENERAL_MENU_PLUGIN, { buttons: popup_buttons })
  }

  return {
    showPopup: () => showMenu(editor, initPopup, 'General', GENERAL_MENU_PLUGIN, AUTOFILL_MENU_PLUGIN, true),
    hidePopup: () => hideMenu(editor, GENERAL_MENU_PLUGIN)
  }
}

Froala.PLUGINS.dateMenuPlugin = function(editor) {
  function initPopup(buttonSource: string, parentPluginName: string) {
    var popup_buttons = ''
    for (let i = 0; i < dateFormatButtons.length; i++) {
      let label = dateFormatButtons[i].label
      let subTitle = dateFormatButtons[i].subLabel
      const buttonCommand = `${label}-${DATE_MENU_PLUGIN}-${parentPluginName}`

      popup_buttons += `<div class="fr-buttons placeholder-button"><button id="${label}" type="button" tabIndex="-1" role="button" title="${label}" class="fr-command fr-btn fr-date" data-cmd="${buttonCommand}">
<span class="title">${label}</span><span class="subTitle">${subTitle}</span><span class="fr-sr-only">${label}</span></button></div>`
      RegisterMenuButton(label, buttonSource, DATE_MENU_PLUGIN, parentPluginName)
    }

    return editor.popups.create(DATE_MENU_PLUGIN, { buttons: popup_buttons })
  }

  function showPopup(buttonLabel: string, parentPluginName: string) {
    const buttonCommand = `${buttonLabel}-${DATE_MENU_PLUGIN}-${parentPluginName}`
    initPopup(buttonLabel, parentPluginName)
    editor.popups.setContainer(DATE_MENU_PLUGIN, editor.$tb)
    var button = editor.$tb.find(`.fr-command[data-cmd="${buttonCommand}"]`)
    if (button) {
      var left = button.offset().left + BUTTON_OFFSET
      var top = editor.opts.toolbarBottom
      editor.popups.show(DATE_MENU_PLUGIN, left, top, button.outerHeight())
    }

    var autofillPopup = editor.popups.get(AUTOFILL_MENU_PLUGIN)
    if (autofillPopup) autofillPopup.addClass('fr-active')

    if (buttonLabel === TODAYS_DATE_STRING) {
      var generalPopup = editor.popups.get(GENERAL_MENU_PLUGIN)
      if (generalPopup) generalPopup.addClass('fr-active')
    } else if (buttonLabel === DATE_OF_BIRTH_STRING) {
      var patientProfilePopup = editor.popups.get(PATIENT_PROFILE_MENU_PLUGIN)
      if (patientProfilePopup) patientProfilePopup.addClass('fr-active')
    } else if (buttonLabel === DATE_STRING) {
      var appointmentPopup = editor.popups.get(APPOINTMENT_MENU_PLUGIN)
      if (appointmentPopup) appointmentPopup.addClass('fr-active')
    }
  }

  function hidePopup() {
    editor.popups.hide(DATE_MENU_PLUGIN)
  }

  return {
    showPopup: showPopup,
    hidePopup: hidePopup
  }
}

Froala.DefineIconTemplate('variable-insert', '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M120-280v-400h720v160h-80v-80H200v240h360v80H120Zm80-80v-240 240Zm664 200L720-303v123h-80v-260h260v80H776l144 144-56 56Z"/></svg>')
Froala.DefineIcon('autofillButton', { Name: 'VariableInsert', ALT: 'Custom Menu', template: 'variable-insert' })
Froala.RegisterCommand('autofillButton', {
  title: 'Add placeholders',
  icon: 'autofillButton',
  undo: false,
  focus: false,
  plugin: AUTOFILL_MENU_PLUGIN,
  callback: function() {
    this.autofillMenuPlugin.showPopup()
  }
})

const RegisterMenuButton = (buttonLabel: string, buttonSource: string, pluginName: string, parentPluginName: string) => {
  let placeholderText = `${buttonSource} - ${buttonLabel}`

  const getButtonLabel = (buttonLabel) => {
    if (buttonLabel === PRACTITIONER_TITLE_LAST_NAME || buttonLabel === PRACTITIONER_TITLE_FULL_NAME) {
      return `(${buttonLabel})`
    } else {
      return buttonLabel
    }
  }

  switch (parentPluginName) {
    case PATIENT_PROFILE_MENU_PLUGIN:
      placeholderText = `Patient profile - ${buttonSource} (${buttonLabel})`
      break
    case APPOINTMENT_MENU_PLUGIN:
      placeholderText = `Appointment - ${buttonSource} ${getButtonLabel(buttonLabel)}`
      break
    case PRONOUN_MENU_PLUGIN:
      placeholderText = `Pronouns - ${buttonSource} (${buttonLabel})`
      break
    case PRACTITIONER_MENU_PLUGIN:
      placeholderText = `${buttonSource} - ${getButtonLabel(buttonLabel)}`
      break
    case GENERAL_MENU_PLUGIN :
      placeholderText = `${buttonSource} (${buttonLabel})`
      break
  }
  switch (pluginName) {
    case PATIENT_HEALTH_DATA_MENU_PLUGIN:
      placeholderText = `Patient - ${buttonLabel}`
      break
    case GENERAL_MENU_PLUGIN:
      placeholderText = `${buttonLabel}`
      break
  }

  const buttonCommand = `${buttonLabel}-${pluginName}-${parentPluginName}`

  Froala.RegisterCommand(buttonCommand, {
    title: buttonLabel,
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function(cmd, val) {
      let cmdParts = cmd.split('-')
      if (cmdParts.length > 2) {
        if (cmdParts[2] === AUTOFILL_MENU_PLUGIN && !secondLayerMenus.includes(cmdParts[2])) {
          thirdLayerMenus.map((menu) => {
            this.popups.hide(menu)
          })
        }
      }
      this.html.insert(renderToStaticMarkup(<><span
        className={'fr-chip-label'}>{placeholderText}</span><span>&nbsp;</span></>))
    }
  })

  return `<div class="fr-buttons placeholder-button">
<button id="${buttonLabel}" type="button" tabIndex="-1" role="button" title="${buttonLabel}" class="fr-command fr-btn" data-cmd="${buttonCommand}">
  <span class="title">${buttonLabel}</span>
  <span class="fr-sr-only">${buttonLabel}</span>
</button>
</div>`
}

const RegisterSubMenuButton = (buttonLabel: string, pluginName: string, parentPluginName: string) => {
  const buttonCommand = `${buttonLabel}-${pluginName}-${parentPluginName}`

  Froala.RegisterCommand(buttonCommand, {
    title: buttonLabel,
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function(cmd, val) {
      switch (pluginName) {
        case PATIENT_PROFILE_MENU_PLUGIN:
          this.patientProfileMenuPlugin.showPopup()
          break
        case PATIENT_HEALTH_DATA_MENU_PLUGIN:
          this.patientHealthDataMenuPlugin.showPopup()
          break
        case APPOINTMENT_MENU_PLUGIN:
          this.appointmentMenuPlugin.showPopup()
          break
        case PRACTITIONER_MENU_PLUGIN:
          this.practitionerMenuPlugin.showPopup()
          break
        case LETTER_PRACTITIONER_MENU_PLUGIN:
          this.letterPractitionerMenuPlugin.showPopup()
          break
        case FAMILY_PRACTITIONER_MENU_PLUGIN:
          this.familyPractitionerMenuPlugin.showPopup()
          break
        case REFERRING_PRACTITIONER_MENU_PLUGIN:
          this.referringPractitionerMenuPlugin.showPopup()
          break
        case LOCATION_MENU_PLUGIN:
          this.locationMenuPlugin.showPopup()
          break
        case APPOINTMENT_LOCATION_MENU_PLUGIN:
          this.appointmentLocationMenuPlugin.showPopup()
          break
        case APPOINTMENT_PRACTITIONER_MENU_PLUGIN:
          this.appointmentPractitionerMenuPlugin.showPopup()
          break
        case GENERAL_MENU_PLUGIN:
          this.generalMenuPlugin.showPopup()
          break
        case DATE_MENU_PLUGIN:
          this.dateMenuPlugin.showPopup(buttonLabel, parentPluginName)
          break
        case PRONOUN_MENU_PLUGIN:
          this.pronounMenuPlugin.showPopup()
          break
      }
    }
  })

  return `<div class="fr-buttons placeholder-button">
<button id="${buttonLabel}" type="button" tabIndex="-1" role="button" title="${buttonLabel}" class="fr-command fr-btn" data-cmd="${buttonCommand}">
  <span class="title">${buttonLabel}</span>${renderToStaticMarkup(<KeyboardArrowRightIcon />)}
  <span class="fr-sr-only">${buttonLabel}</span>
</button>
</div>`
}

export function findAllTextNodes(node, searchText, foundTextNodes: any[] = []) {
  node.childNodes.forEach(child => {
    if (child.nodeType === Node.TEXT_NODE) {
      if (child.textContent.includes(searchText)) {
        foundTextNodes.push(child)
      }
    } else {
      findAllTextNodes(child, searchText, foundTextNodes) // Recurse for non-text child nodes
    }
  })
  return foundTextNodes
}

function selectPlaceholder(placeholder, placeholderText) {
  const range = document.createRange()
  range.setStart(placeholder.node, placeholder.index)
  range.setEnd(placeholder.node, placeholder.index + placeholderText.length)
  const selection = window.getSelection()
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

// Compare positions between two nodes to determine if node1 comes after node2 in the document
// Provide an accurate order to receive a number (node 2 is after 1),
// otherwise providing an inaccurate order returns 0 (node 1 is after 2)
export function isAfterInDocument(node1, node2) {
  return node1.compareDocumentPosition(node2) & Node.DOCUMENT_POSITION_FOLLOWING
}

interface RichTextEditorProps {
  content: string
  handleContentChange: (value: string) => void
  documentView?: boolean
  viewCode?: boolean
  disabled?: boolean
  textEditorHeight?: string
  placeholderContent?: string
  placeholderText?: string
  loopPlaceholders?: boolean
  showTemplateToolbar?: boolean
  sx?: SxProps<Theme>
  dataTestId: string,
  editorRef?: React.MutableRefObject<any>,
  tenantId?: string,
  setContent?: (content: string) => void
  uploadFiles: (files: any[], isDocumentImported: boolean) => Promise<any[] | null>,
  getFileTypeUriWithToken: (file: any) => Promise<string | null>,
  getFileDetailsCall: DocumentNode,
  froalaKey: string,
  setImageUploading: (loading: boolean) => void,
  addImageFileToEditor: (imageId: string) => void,
  removeImageFileFromEditor: (imageId: string) => void,
  initialImagesLoaded?: MutableRefObject<boolean>
}

const RichTextEditor = (
  {
    content,
    handleContentChange,
    documentView = true,
    viewCode = false,
    disabled = false,
    textEditorHeight = '600px',
    placeholderContent = 'Edit here',
    placeholderText,
    loopPlaceholders = false,
    showTemplateToolbar = false,
    sx,
    dataTestId,
    editorRef,
    tenantId,
    setContent,
    uploadFiles,
    getFileTypeUriWithToken,
    getFileDetailsCall,
    froalaKey,
    setImageUploading,
    addImageFileToEditor,
    removeImageFileFromEditor,
    initialImagesLoaded,
  }: RichTextEditorProps) => {
  const [textEditor, setTextEditor] = useState<any>('')

  let editor: any = '' // initializing this variable to be used in the events

  useEffect(() => {
    if (!textEditor) {
      return
    }

    if (disabled) {
      textEditor.edit.off()
    } else {
      textEditor.edit.on()
    }
  }, [textEditor, disabled])

  useEffect(() => {
    if (!textEditor) {
      return
    }

    if (!!content && !initialImagesLoaded?.current) {
      parseContentAndLoadImages()
    }

    const highlightedChip = textEditor.el.getElementsByClassName('highlighted')
    if (highlightedChip.length > 0) {
      highlightedChip[0].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }, [textEditor, content])

  const [getFileDetailsFromUrl] = useLazyQuery(getFileDetailsCall, {
    onError: (error) => {
      console.error('Error occurred retrieving signature file: ' + error)
    },
    fetchPolicy: 'cache-and-network'
  })

  const parseContentAndLoadImages = async () => {
    let newContent = content
    let imageMatch
    const imageRegex = /<img\s+[^>]*src="http[^"]*"[^>]*"/g
    const imageTagToNewImageTagMap = new Map()
    while ((imageMatch = imageRegex.exec(content)) !== null) {
      const imageTag = imageMatch[0]
      const imageSourceRegex = /src="([^"]*)"/
      const srcMatch = imageSourceRegex.exec(imageTag)
      if (!srcMatch || srcMatch.length < 2) return
      const url = srcMatch[1]
      if (imageTagToNewImageTagMap.has(url)) return
      let fileDetailsData = await getFileDetailsFromUrl({
        variables: { url: url, tenantId: tenantId }
      })

      const details = _get(fileDetailsData.data, 'tenant.file.fileDetailsFromUrl', null)
      const existingFile = { fileUri: url, ...details }
      let fileUriWithToken = await getFileTypeUriWithToken(existingFile)
      let newTag = imageTag.replace(url, fileUriWithToken)
      newTag = newTag.replace('<img', `<img id=${existingFile.id}`)
      imageTagToNewImageTagMap.set(imageTag, newTag)
    }

    imageTagToNewImageTagMap.forEach((newImageTag, oldImageTag) => {
      newContent = newContent.replaceAll(oldImageTag, newImageTag)
    })

    setContent?.(newContent)
  }

  const autofillPlugins = [AUTOFILL_MENU_PLUGIN, PATIENT_PROFILE_MENU_PLUGIN,
    PATIENT_HEALTH_DATA_MENU_PLUGIN, APPOINTMENT_MENU_PLUGIN, PRACTITIONER_MENU_PLUGIN,
    LETTER_PRACTITIONER_MENU_PLUGIN, FAMILY_PRACTITIONER_MENU_PLUGIN, REFERRING_PRACTITIONER_MENU_PLUGIN,
    LOCATION_MENU_PLUGIN, DATE_MENU_PLUGIN, PRONOUN_MENU_PLUGIN, GENERAL_MENU_PLUGIN, APPOINTMENT_LOCATION_MENU_PLUGIN,
    APPOINTMENT_PRACTITIONER_MENU_PLUGIN]

  const plugins = ['charCounter', 'image', 'imageTUI', 'codeBeautifier', 'codeView',
    'draggable', 'align', 'link', 'fontFamily', 'fontSize', 'colors', 'fontAwesome', 'emoticons', 'inlineClass', 'inlineStyle',
    'fullscreen',
    'embedly', 'help',
    'lineBreaker', 'lineHeight', 'lists', 'markdownSupport', 'paragraphFormat', 'paragraphStyle', 'print', 'quickInsert',
    'quote', 'save', 'table', 'url', 'video', 'wordPaste', 'trackChanges', 'specialCharacters',
    'filesManager', 'file', 'entities']

  if (showTemplateToolbar) {
    plugins.push(...autofillPlugins)
  }

  const getFileTypeFromImage = (image: Blob) => {
    return image.type.split('/')?.[1]
  }

  const config = {
    key: froalaKey,
    attribution: false,
    pluginsEnabled: plugins,
    fontFamilySelection: true,
    fontSizeSelection: true,
    paragraphFormatSelection: true,
    listAdvancedTypes: false,
    charCounterCount: false,
    htmlUntouched: true,
    htmlDoNotWrapTags: ['script', 'style', 'p', 'br', 'span'],
    events: {
      initialized: function() {
        editor = this
        setTextEditor(editor)

        if (!editor.$tb) {
          return
        }

        // Add data-testids to the view and all commands
        const buttons = editor.$tb.find('button.fr-command')
        buttons.each((_, btn) => {
          btn.setAttribute('data-testid', `${dataTestId}-${btn.getAttribute('data-cmd')}`)
        })
        const linkLists = editor.$tb.find('ul')
        linkLists.each((_, list) => {
          const links: HTMLCollection = list.getElementsByTagName('a')
          Array.from(links).forEach((link, index) => {
            if (link.classList.contains('fr-command')) {
              link.setAttribute('data-testid', `${dataTestId}-${link.getAttribute('data-cmd')}-${index}`)
            }
          })
        })
        editor.$el.attr('data-testid', `${dataTestId}-view`)
      },
      click: function(clickEvent) {
        const autofillPopupMenu = document.querySelector('.fr-popup')
        if (!autofillPopupMenu?.contains(clickEvent.target)) {
          editor.popups.hideAll()
        }
      },
      keydown: (keydownEvent) => {
        if (keydownEvent.key === 'Tab' && placeholderText) {
          // Get all the placeholders in the editor
          const allTextNodes = findAllTextNodes(editor.el, placeholderText)
          let placeholders: any[] = []
          allTextNodes.forEach(textNode => {
            let index = textNode.textContent.indexOf(placeholderText)
            while (index !== -1) {
              placeholders.push({ node: textNode, index: index })
              index = textNode.textContent.indexOf(placeholderText, index + placeholderText.length)
            }
          })
          if (!placeholders.length) return

          // Get the current selection and cursor position
          const selection = window.getSelection()
          let cursorPosition = selection?.anchorOffset || 0
          let currentNode = selection?.anchorNode

          // Determine the next placeholder to select based on current cursor position
          let foundNextPlaceholder = false
          for (let i = 0; i < placeholders.length; i++) {
            const placeholder = placeholders[i]
            // Check if this placeholder is after the current cursor position in the document
            if (placeholder.node === currentNode && placeholder.index > cursorPosition) {
              selectPlaceholder(placeholder, placeholderText)
              foundNextPlaceholder = true
              break
            } else if (placeholder.node !== currentNode && isAfterInDocument(currentNode, placeholder.node)) {
              selectPlaceholder(placeholder, placeholderText)
              foundNextPlaceholder = true
              break
            }
          }

          // If no next placeholder was found or the cursor is at the end of the editor, select the first placeholder or loop
          if (!foundNextPlaceholder) {
            if (loopPlaceholders) {
              selectPlaceholder(placeholders[0], placeholderText)
            } else {
              // At the last placeholder and not looping, allow tab to work normally
              window.getSelection()?.removeAllRanges()
              return
            }
          }
          keydownEvent.preventDefault()
        }
      },
      'image.beforeUpload': async function(images) {
        const imageInputs: File[] = []
        if (!images[0]) return false
        let image = images[0]

        try {
          setImageUploading(true)
          const fileType = getFileTypeFromImage(image)
          const temp = new File([image], 'uploadedImage.' + fileType)
          imageInputs.push(
            temp
          )

          const processedFiles = await uploadFiles(imageInputs, false)

          let file = processedFiles![0]

          if (!file) return false
          const uri = await getFileTypeUriWithToken(file)
          editor.image.insert(uri, false, { 'id': file.id }, editor.image.get(), null)
        } catch (error) {
        } finally {
          setImageUploading(false)
        }

        return false
      },
      'image.uploaded': function($img, response) {
      },
      'image.inserted': function($img, response) {
      },
      'image.loaded': function($img) {
        if (!!$img.attr('id') && (!content || !setContent || !!initialImagesLoaded?.current)) {
          addImageFileToEditor($img.attr('id'))
        }
      },
      'image.beforeRemove': function($img) {
      },
      'image.replaced': function($img) {
      },
      'image.removed': function($img) {
        if (!content || !setContent || !!initialImagesLoaded?.current) {
          removeImageFileFromEditor($img.attr('id'))
        }
      },
      'image.error': function(error, response) {
        console.error('error', error, response)
      }
    },
    imageUpload: true,
    imageMaxSize: 5 * 1024 * 1024,
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    imageEditButtons: ['imageAlign', 'imageDisplay']
  }

  const mainButtons = [
    'undo', 'redo',
    'bold', 'italic', 'underline',
    'paragraphFormat', 'fontFamily', 'fontSize',
    'formatOL', 'formatUL', 'align', 'indent', 'outdent', 'insertTable', 'autofillButton'
  ]

  const mscButtons = [
    'help',
    'strikeThrough', 'subscript', 'superscript', 'clearFormatting', 'lineHeight',
    'insertImage', 'emoticons',
    'fullscreen'
  ]

  const toolbarButtons = (buttonAmount: number) => {
    const subset = mainButtons.slice(0, buttonAmount)
    const remaining = mainButtons.slice(buttonAmount, mainButtons.length)
    return {
      moreText: {
        buttons: subset,
        align: 'left',
        buttonsVisible: buttonAmount
      },
      moreMisc: {
        buttons: viewCode ? [...remaining, ...mscButtons, 'html'] : [...remaining, ...mscButtons],
        align: 'right',
        buttonsVisible: buttonAmount === mainButtons.length ? 1 : 0
      }
    }
  }

  return (
    <Box
      sx={{
        mt: 1,
        minWidth: '233px',
        ...sx,
      }}
    >
      <FroalaEditor
        ref={editorRef}
        tag="textarea"
        config={{
          ...config,
          placeholderText: placeholderContent,
          documentReady: documentView,
          popupButtons: ['autofillButton'],
          toolbarButtons: toolbarButtons(mainButtons.length),
          toolbarButtonsSM: toolbarButtons(6),
          toolbarButtonsXS: toolbarButtons(5),
          height: textEditorHeight
        }}
        model={content}
        onModelChange={handleContentChange}
      />
    </Box>
  )
}

export interface ControlledRichTextEditorProps extends Omit<RichTextEditorProps, 'content' | 'handleContentChange' | 'setContent'> {
  name: string,
  onChange?: (value: string) => void,
}

export const ControlledRichTextEditor = ({
  name,
  onChange,
  ...props
}: ControlledRichTextEditorProps) => {
  const { control } = useFormContext()
  const initialImagesLoaded = useRef<boolean>(false)

  return <Controller
    name={name}
    control={control}
    render={({ field: { onChange: controlledOnChange, value, ref }, fieldState: { error } }) => {
      return (
        <RichTextEditor
          {...props}
          content={value}
          initialImagesLoaded={initialImagesLoaded}
          setContent={onChange}
          handleContentChange={
            (newValue) => {
              if (newValue !== value) {
                controlledOnChange(newValue)
                if (onChange && !initialImagesLoaded?.current) {
                  onChange(newValue)
                  initialImagesLoaded.current = true
                }
              }
            }
          }
        />
      )
    }}
  />
}

export default ControlledRichTextEditor